const DATA_POINTS = {
    basicInfoCommonDataPoints: [
        {
            id: 'moat',
            dataType: 'string',
            align: 'right',
            minWidth: 150
        },
        {
            id: 'valuation',
            dataType: 'string',
            align: 'right',
            minWidth: 150
        },
        {
            id: 'financialHealth',
            dataType: 'string',
            align: 'right',
            minWidth: 100
        },
        {
            id: 'uncertainty',
            dataType: 'string',
            align: 'right',
            minWidth: 100
        }
    ],
    highlightsBasicInfoDataPoints: [
        {
            id: 'rating',
            dataType: 'number'
        },
        {
            id: 'fairValue',
            dataType: 'number'
        },
        {
            id: 'lastPrice',
            dataType: 'number'
        },
        {
            id: 'tradeTime',
            dataType: 'string'
        },
        {
            id: 'ratingUpdateDate',
            dataType: 'date'
        },
        {
            id: 'fairValueUpdateDate',
            dataType: 'date'
        },
        {
            id: 'updateDate',
            dataType: 'date'
        },
        {
            id: 'isMomentumFilterFlag',
            dataType: 'boolean'
        }
    ],
    competitorsBasicInfoDataPoints: [
        {
            id: 'name',
            dataType: 'string',
            align: 'left',
            width: 200
        },
        {
            id: 'rating',
            dataType: 'starRating',
            align: 'right',
            width: 100
        },
        {
            id: 'priceFairValue',
            align: 'right',
            width: 150,
            dataType: 'bargain'
        }
    ]
};

export function getHighlightsBasicInfoDataPoints() {
    return DATA_POINTS.basicInfoCommonDataPoints.concat(
        DATA_POINTS.highlightsBasicInfoDataPoints
    );
}

export function getCompetitorsBasicInfoDataPoints() {
    return DATA_POINTS.competitorsBasicInfoDataPoints.concat(
        DATA_POINTS.basicInfoCommonDataPoints
    );
}

export function getHighlightsRows() {
    return [
        'rating',
        'priceCompareFairValue',
        'moat',
        'valuation',
        'financialHealth',
        'uncertainty'
    ];
}
